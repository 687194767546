<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>提现管理</template>
      <template v-slot:secondMenu>提现列表</template>
    </breadcrumb-nav>
    <el-card>
      <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
        <el-form-item label="提现方式" prop="status">
          <el-select v-model="listQuery.wType" style="width:100%" @clear="cancelStatus" clearable placeholder="选择状态">
            <el-option
              v-for="item in wTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="listQuery.status" style="width:100%" @clear="cancelStatus" clearable placeholder="选择状态">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期范围" prop="date">
          <el-date-picker
            v-model="listQuery.date"
            type="daterange"
            align="right"
            prop="date"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="resetForm('listQuery')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
    <el-table class="custom-order-table" :data="list" border stripe style="margin-top: 20px">
      <el-table-column prop="type" label="提现方式">
        <template slot-scope="scope">{{ scope.row.type | formatType }}</template>
      </el-table-column>
      <el-table-column prop="userId" label="用户id">
      </el-table-column>
      <el-table-column prop="account" label="账号">
      </el-table-column>
      <el-table-column prop="name" label="姓名">
      </el-table-column>
      <el-table-column prop="orderStatus" label="状态">
        <template slot-scope="scope">{{ scope.row.status | formatStatus }}</template>
      </el-table-column>
      <el-table-column prop="refundPrice" label="申请金额" width="120px">
        <template slot-scope="scope">{{ scope.row.amount | floatFormat }}</template>
      </el-table-column>
      <el-table-column prop="createTime" label="申请时间">
        <template slot-scope="scope">{{ scope.row.createTime | dateFormat }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-row>
            <el-button v-show="scope.row.status !== 1" type="primary" icon="el-icon-view" size="mini"
                       @click="view(scope.row.applyId)">详情
            </el-button>
            </el-row>
          <el-row>
            <el-button v-show="scope.row.status === 1" type="success" icon="el-icon-s-check" size="mini"
                       @click="audit(scope.row.applyId)">审核
            </el-button>
            </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[5, 10]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { getWithDrawListRequest } from '../../network/user'
export default {
  name: 'List',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatStatus (value) {
      switch (value) {
        case 1:
          return '审核中'
        case 2:
          return '同意'
        case 3:
          return '拒绝'
        case 4:
          return '成功'
        case 5:
          return '失败'
        default:
          return '未知'
      }
    },
    formatType (value) {
      switch (value) {
        case 1:
          return '微信'
        case 2:
          return '支付宝'
        default:
          return '未知'
      }
    }
  },
  data () {
    return {
      statusOptions: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '申请中',
          value: 1
        },
        {
          label: '拒绝',
          value: 3
        },
        {
          label: '成功',
          value: 4
        },
        {
          label: '失败',
          value: 5
        }
      ],
      wTypeOptions: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '微信',
          value: 1
        },
        {
          label: '支付宝',
          value: 2
        }
      ],
      listQuery: {
        page: 1,
        size: 10,
        wType: 0,
        status: 0,
        date: '',
        isAdmin: 1
      },
      list: [],
      total: 0
    }
  },
  mounted () {
    this.search()
  },
  methods: {
    search () {
      const query = {
        page: this.listQuery.page,
        size: this.listQuery.size,
        status: this.listQuery.status,
        type: this.listQuery.wType,
        isAdmin: this.listQuery.isAdmin
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.from = this.listQuery.date[0]
        query.to = this.listQuery.date[1]
      }
      console.log('query:', query)
      getWithDrawListRequest(query).then(res => {
        const result = res.data
        // console.log('task list:', result.data.list)
        if (res.status !== 200) {
          return this.alertMessage('获取提现列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    resetForm () {
      this.$refs.listQuery.resetFields()
    },
    cancelStatus () {
      this.listQuery.status = 0
    },
    audit (applyId) {
      this.$router.push({ path: '/withdrawDetail', query: { applyId: applyId } })
    },
    view (applyId) {
      this.$router.push({ path: '/withdrawDetail', query: { applyId: applyId } })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.search()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.search()
    }
  }
}
</script>

<style scoped>

</style>
